export enum GameProviderId {
  Originals = 1,
  Ezugi = 0,
  TvBet = 2,
  Evolutiongames = 4,
  GoldenRace = 7,
  Spinmatic = 8,
  Endorphina = 19,
  WizardGames = 20,
  Habanero = 21,
  PragmaticPlay = 22,
  TomHorn = 23,
  GameArt = 26,
  OnexTwoNetwork = 29,
  Triplecherry = 30,
  Mobilots = 41,
  OneTouch = 43,
  Slotmill = 51,
  Thunderkick = 54,
  Yggdrasil = 56,
  SmartSoft = 58,
  IronDogStudio = 59,
  LuckyStreak = 61,
  RedRake = 66,
  LEAP = 67,
  BlueprintGaming = 74,
  KalambaGames = 75,
  RealtimeGaming = 76,
  SpearheadStudios = 77,
  Nolimitcity = 79,
  KironInteractive = 84,
  FantasmaGames = 85,
  OMIGaming = 86,
  DragoonSoft = 88,
  NetEnt = 92,
  SkywindCasino = 96,
  Quickspin = 98,
  MascotGaming = 102,
  Evoplay = 103,
  WoohooGames = 104,
  ElkStudios = 105,
  KaGaming = 106,
  Platipus = 116,
  CaletaGaming = 117,
  Gamzix = 118,
  RevolverGaming = 120,
  Wazdan = 121,
  PrintStudios = 125,
  Ezugipremium = 126,
  Evolutiongamespremium = 128,
  Pragmaticlive = 129,
  MrSlotty = 132,
  TrueLab = 133,
  Betsolutions = 134,
  BGaming = 136,
  Fazi = 138,
  Tpg = 139,
  VirtualGeneration = 142,
  GamesInc = 143,
  Betgamestv = 144,
  Felix = 145,
  NetGaming = 148,
  FunTaGaming = 149,
  SevenMojosLive = 151,
  SevenMojosSlots = 152,
  Fugaso = 154,
  FunkyGames = 158,
  Mancala = 159,
  CoolGames = 160,
  Spinthon = 161,
  Xpg = 163,
  Apollo = 197,
  CQ9Casino = 205,
  Skywind = 208,
  BigTimeGaming = 209,
  Microgaming = 244,
  EURASIANGaming = 252,
  Jili = 288,
  FastGamesbyGalaxsys = 297,
  CTInteractive = 301,
  OnlyPlay = 313,
  Spinberry = 314,
  TurboGames = 321,
  AmigoGaming = 324,
  AGTsoftware = 325,
  Elbet = 326,
  RubyPlay = 328,
  Espresso = 329,
  FCGame = 333,
  Playson = 343,
  Spribe = 344,
  BarbaraBang = 348,
  ZeusPlay = 349,
  RedTigerGaming = 354,
  Playtech = 356,
  PGSoft = 358,
  Amatic = 367,
  PushGaming = 373,
  Thirtyaks = 374,
  Vivo = 375,
}
