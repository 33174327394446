import { FormGroup, Input } from '@package/components';
import { GameCardTooltipProps } from './props';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';

export default function GameCardTooltip({ game }: GameCardTooltipProps) {
  const { t } = useTranslation('common');
  return (
    <div className={styles.container}>
      <FormGroup label={t('effective_rtp')}>
        <Input readOnly value={game?.rtp} />
      </FormGroup>
    </div>
  );
}
