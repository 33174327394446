import { useEffect, useState } from 'react';
import { ImageDimensionLoaderProps } from './props';
import { Image } from '@starsoft/common/components';
import { imageWidthDeviceMapping } from '@/constants/imageDeviceMapping';
import { Device } from '@/enums/device';
import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';

export default function ImageDimensionLoader({
  imageMapping,
  ...props
}: ImageDimensionLoaderProps) {
  const [clientDevice, setClientDevice] = useState<Device | undefined>(
    undefined,
  );
  const device: Device | undefined = useSelector(
    (state: RootState) => state.casino.device,
  );
  const imageDesktop: string | undefined = imageMapping.get(Device.Desktop);
  const imageTablet: string | undefined = imageMapping.get(Device.Tablet);
  const imageMobile: string | undefined = imageMapping.get(Device.Mobile);

  function handleSetClientDevice() {
    setClientDevice(device);
  }

  useEffect(handleSetClientDevice, [device]);

  return (
    <>
      {(clientDevice === Device.Desktop ||
        (clientDevice === Device.Tablet && !imageTablet) ||
        !clientDevice) &&
        imageDesktop && (
          <Image
            src={imageDesktop}
            alt={props?.alt}
            {...props}
            width={imageWidthDeviceMapping.get(Device.Desktop)}
            height={imageWidthDeviceMapping.get(Device.Desktop)}
          />
        )}

      {(clientDevice === Device.Tablet ||
        (clientDevice === Device.Mobile && !imageMobile) ||
        !clientDevice) &&
        imageTablet && (
          <Image
            src={imageTablet}
            alt={props?.alt}
            {...props}
            width={imageWidthDeviceMapping.get(Device.Tablet)}
            height={imageWidthDeviceMapping.get(Device.Tablet)}
          />
        )}

      {(clientDevice === Device.Mobile || !clientDevice) && imageMobile && (
        <Image
          src={imageMobile}
          alt={props?.alt}
          {...props}
          width={imageWidthDeviceMapping.get(Device.Mobile)}
          height={imageWidthDeviceMapping.get(Device.Mobile)}
        />
      )}
    </>
  );
}
