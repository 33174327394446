import { GameCardProps } from '../props';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import GameCardTooltip from './Tooltip';
import { Game } from '@/models/game';
import WalletService from '@/services/WalletService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { toggleSearch } from '@/lib/store/casino/actions';
import React, { useMemo } from 'react';
import { Device } from '@/enums/device';
import ImageDimensionLoader from '@/components/core/ImageDimensionLoader';
import dynamic from 'next/dynamic';
import { CoinID } from '@/enums/coinId';
import { GameProviderId } from '@/enums/gameProviderId';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { useRouter } from 'next/router';
import { ToolTip } from '@package/components';
import { gameProvidersMap } from '@/constants/build/game_providers';
const GameCardV2Skeleton = dynamic(() => import('./Skeleton'));

export default function GameCard({
  game,
  priority,
  isLoading,
  showTotalPaid,
  largerMobileCardSize,
}: GameCardProps) {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const openSearch = useSelector((state: RootState) => state.casino.openSearch);
  const imageMapping: Map<Device, string> = useMemo(
    () =>
      new Map<Device, string>([
        [
          Device.Desktop,
          `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games/300x300${game?.image}`,
        ],
        [
          Device.Tablet,
          `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games/150x150${game?.image}`,
        ],
      ]),
    [game],
  );
  const { query } = useRouter();
  const tag: GameTagFilter | undefined = query.tag as GameTagFilter;

  function onClick() {
    if (!openSearch) {
      return;
    }

    dispatch(toggleSearch());
  }

  function handleTargetProvider(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const provider = gameProvidersMap.get(game?.provider?.id);
    push(`/games/providers/${provider?.slug ?? 'originals'}`);
    onClick();
  }

  if (isLoading) {
    return (
      <GameCardV2Skeleton
        showTotalPaid={showTotalPaid}
        largerMobileCardSize={largerMobileCardSize}
      />
    );
  }

  return (
    <Link
      href={
        game?.provider?.id === GameProviderId.Originals
          ? `/games/tag/originals/${game?.slug}`
          : `/games/tag/${tag ?? GameTagFilter.Slots}/${game?.slug}`
      }
      className={`${styles.container} ${largerMobileCardSize ? styles['container--larger-mobile-card-size'] : ''}`}
      onClick={onClick}
    >
      <div className={styles['container__image-container']}>
        <div className={styles['container__image-container__info']}>
          <div className={styles['container__image-container__info__title']}>
            {game?.name}
          </div>
          <div
            className={styles['container__image-container__info__play__button']}
          >
            <i className="fa-solid fa-play" />
          </div>
          <div />
        </div>
        <ImageDimensionLoader
          imageMapping={imageMapping}
          imagePreview={game?.previewImage}
          loading={priority ? 'eager' : 'lazy'}
          className={styles['container__image-container__image']}
        />
      </div>
      <div className={styles.container__content}>
        <div className={styles.container__content__row}>
          <div className={styles.container__content__row__title}>
            {game?.name}
          </div>
          <div className={styles['container__hide-mobile']}>
            <ToolTip titleComponent={<GameCardTooltip game={game as Game} />}>
              <i
                className={`${styles.container__content__row__icon} fa-duotone fa-circle-info`}
              />
            </ToolTip>
          </div>
        </div>
        {(showTotalPaid && (
          <div className={styles.container__content__amount}>
            <div className={styles.container__content__amount__coin__amount}>
              <i className="fa-solid fa-dollar-sign" />
              {WalletService.maskCurrency({
                value: game?.dailyPayout ?? 0,
                coinId: CoinID.USD,
                hideSymbol: true,
              })}
            </div>
          </div>
        )) || (
          <div className={styles['container__hide-mobile']}>
            <div
              className={styles.container__content__description}
              onClick={handleTargetProvider}
            >
              {game?.provider?.name}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
