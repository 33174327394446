import { GameProviderId } from '@/enums/gameProviderId';
import { GameProvider } from '@/models/game/provider';

export const gameProviders: Partial<GameProvider>[] = [
  {
    id: 1,
    slug: 'ice-games',
    name: 'Originals',
    image: 'ice-game.svg',
  },
  {
    id: 2,
    slug: 'pgsoft',
    name: 'PG Soft',
    image: 'pgsoft.svg',
  },
  {
    id: 3,
    slug: 'spribe',
    name: 'Spribe',
    image: 'spribe.svg',
  },
  {
    id: 4,
    slug: 'pragmaticexternal',
    name: 'Pragmatic Play',
    image: 'pragmaticexternal.svg',
  },
  {
    id: 5,
    slug: 'evolution',
    name: 'Evolution',
    image: 'evolution.svg',
  },
  {
    id: 6,
    slug: 'playtech',
    name: 'Playtech',
    image: 'playtech.svg',
  },
  {
    id: 7,
    slug: '1x2gaming',
    name: 'One x Two Gaming',
    image: '1x2gaming.svg',
  },
  {
    id: 8,
    slug: '1spin4win',
    name: 'OneSpinForWin',
    image: '1spin4win.svg',
  },
  {
    id: 9,
    slug: '3oaks',
    name: 'Three Oaks',
    image: '3oaks.svg',
  },
  {
    id: 10,
    slug: '5men',
    name: 'Five Men Gaming',
    image: '5men.svg',
  },
  {
    id: 11,
    slug: 'alg',
    name: 'Absolute Live Gaming',
    image: 'alg.svg',
  },
  {
    id: 12,
    slug: 'amatic',
    name: 'Amatic',
    image: 'amatic.svg',
  },
  {
    id: 13,
    slug: 'amigo',
    name: 'Amigo',
    image: 'amigo.svg',
  },
  {
    id: 14,
    slug: 'apparat',
    name: 'Apparat',
    image: 'apparat.svg',
  },
  {
    id: 15,
    slug: 'authentic',
    name: 'Authentic',
    image: 'authentic.svg',
  },
  {
    id: 16,
    slug: 'avatarux',
    name: 'Avatarux',
    image: 'avatarux.svg',
  },
  {
    id: 17,
    slug: 'barbarabang',
    name: 'Barbara Bang',
    image: 'barbarabang.svg',
  },
  {
    id: 18,
    slug: 'belatra',
    name: 'Belatra',
    image: 'belatra.svg',
  },
  {
    id: 19,
    slug: 'beterlive',
    name: 'BETER live',
    image: 'beterlive.svg',
  },
  {
    id: 20,
    slug: 'bsg',
    name: 'Bet Soft',
    image: 'bsg.svg',
  },
  {
    id: 21,
    slug: 'betsolutions',
    name: 'Betsolutions',
    image: 'betsolutions.svg',
  },
  {
    id: 22,
    slug: 'bfgames',
    name: 'BF Games',
    image: 'bfgames.svg',
  },
  {
    id: 23,
    slug: 'blueprint',
    name: 'Blueprint Gaming',
    image: 'blueprint.svg',
  },
  {
    id: 24,
    slug: 'booming',
    name: 'Booming Games',
    image: 'booming.svg',
  },
  {
    id: 25,
    slug: 'caleta',
    name: 'Caleta',
    image: 'caleta.svg',
  },
  {
    id: 26,
    slug: 'clawbuster',
    name: 'Clawbuster',
    image: 'clawbuster.svg',
  },
  {
    id: 27,
    slug: 'copacabana',
    name: 'Copacabana',
    image: 'copacabana.svg',
  },
  {
    id: 28,
    slug: 'technology',
    name: 'CT Interactive',
    image: 'technology.svg',
  },
  {
    id: 29,
    slug: 'egt',
    name: 'EGT',
    image: 'egt.svg',
  },
  {
    id: 30,
    slug: 'elk',
    name: 'Elk Studios',
    image: 'elk.svg',
  },
  {
    id: 31,
    slug: 'endorphina',
    name: 'Endorphina',
    image: 'endorphina.svg',
  },
  {
    id: 32,
    slug: 'hacksaw',
    name: 'Hacksaw Gaming',
    image: 'hacksaw.svg',
  },
  {
    id: 33,
    slug: 'ezugi',
    name: 'Ezugi',
    image: 'ezugi.svg',
  },
  {
    id: 34,
    slug: 'nolimit',
    name: 'Nolimit City',
    image: 'nolimit.svg',
  },
  {
    id: 35,
    slug: 'evoplay',
    name: 'Evoplay',
    image: 'evoplay.svg',
  },
  {
    id: 36,
    slug: 'expanse',
    name: 'Expanse',
    image: 'expanse.svg',
  },
  {
    id: 37,
    slug: 'fantasma',
    name: 'Fantasma Games',
    image: 'fantasma.svg',
  },
  {
    id: 38,
    slug: 'fazi',
    name: 'Fazi',
    image: 'fazi.svg',
  },
  {
    id: 39,
    slug: 'gameart',
    name: 'Game Art',
    image: 'gameart.svg',
  },
  {
    id: 40,
    slug: 'gamebeat',
    name: 'Game Beat',
    image: 'gamebeat.svg',
  },
  {
    id: 41,
    slug: 'gaming1',
    name: 'Gaming One',
    image: 'gaming1.svg',
  },
  {
    id: 42,
    slug: 'gamingcorps',
    name: 'Gaming Corps',
    image: 'gamingcorps.svg',
  },
  {
    id: 43,
    slug: 'gamzix',
    name: 'Gamzix',
    image: 'gamzix.svg',
  },
  {
    id: 44,
    slug: 'habanero',
    name: 'Habanero',
    image: 'habanero.svg',
  },
  {
    id: 45,
    slug: 'highfive',
    name: 'High Five Games',
    image: 'highfive.svg',
  },
  {
    id: 46,
    slug: 'hollegames',
    name: 'Holle Games',
    image: 'hollegames.svg',
  },
  {
    id: 47,
    slug: 'onetouch',
    name: 'One Touch',
    image: 'onetouch.svg',
  },
  {
    id: 48,
    slug: 'igrosoft',
    name: 'Igrosoft',
    image: 'igrosoft.svg',
  },
  {
    id: 49,
    slug: 'igtech',
    name: 'iG Tech',
    image: 'igtech.svg',
  },
  {
    id: 50,
    slug: 'inout',
    name: 'In Out',
    image: 'inout.svg',
  },
  {
    id: 51,
    slug: 'isoftbet',
    name: 'Isoftbet',
    image: 'isoftbet.svg',
  },
  {
    id: 52,
    slug: 'jaderabbit',
    name: 'Jade Rabbit Studio',
    image: 'jaderabbit.svg',
  },
  {
    id: 53,
    slug: 'kagaming',
    name: 'KA Gaming',
    image: 'kagaming.svg',
  },
  {
    id: 54,
    slug: 'kalamba',
    name: 'Kalamba Games',
    image: 'kalamba.svg',
  },
  {
    id: 55,
    slug: 'livesolutions',
    name: 'Live Solutions',
    image: 'livesolutions.svg',
  },
  {
    id: 56,
    slug: 'lucky',
    name: 'Lucky',
    image: 'lucky.svg',
  },
  {
    id: 57,
    slug: 'luckystreak',
    name: 'Lucky Streak',
    image: 'luckystreak.svg',
  },
  {
    id: 58,
    slug: 'mancala',
    name: 'Mancala Gaming',
    image: 'mancala.svg',
  },
  {
    id: 59,
    slug: 'mascot',
    name: 'Mascot',
    image: 'mascot.svg',
  },
  {
    id: 60,
    slug: 'mgagames',
    name: 'MGA Games',
    image: 'mgagames.svg',
  },
  {
    id: 61,
    slug: 'mrslotty',
    name: 'Mr Slotty',
    image: 'mrslotty.svg',
  },
  {
    id: 62,
    slug: 'mplay',
    name: 'Mplay',
    image: 'mplay.svg',
  },
  {
    id: 63,
    slug: 'netgame',
    name: 'Netgame',
    image: 'netgame.svg',
  },
  {
    id: 64,
    slug: 'novomatic',
    name: 'Novomatic',
    image: 'novomatic.svg',
  },
  {
    id: 65,
    slug: 'nucleus',
    name: 'Nucleus Gaming',
    image: 'nucleus.svg',
  },
  {
    id: 66,
    slug: 'octoplay',
    name: 'Octoplay',
    image: 'octoplay.svg',
  },
  {
    id: 67,
    slug: 'onlyplay',
    name: 'Onlyplay',
    image: 'onlyplay.svg',
  },
  {
    id: 68,
    slug: 'oryx',
    name: 'Oryx Gaming',
    image: 'oryx.svg',
  },
  {
    id: 69,
    slug: 'pateplay',
    name: 'Pateplay',
    image: 'pateplay.svg',
  },
  {
    id: 70,
    slug: 'platipus',
    name: 'Platipus',
    image: 'platipus.svg',
  },
  {
    id: 71,
    slug: 'playngo',
    name: 'Play And Go',
    image: 'playngo.svg',
  },
  {
    id: 72,
    slug: 'popiplay',
    name: 'Popiplay',
    image: 'popiplay.svg',
  },
  {
    id: 73,
    slug: 'pushgaming',
    name: 'Push Gaming',
    image: 'pushgaming.svg',
  },
  {
    id: 74,
    slug: 'quickfire',
    name: 'Quickfire',
    image: 'quickfire.svg',
  },
  {
    id: 75,
    slug: 'quickspin',
    name: 'Quickspin',
    image: 'quickspin.svg',
  },
  {
    id: 76,
    slug: 'rawgaming',
    name: 'Raw iGaming',
    image: 'rawgaming.svg',
  },
  {
    id: 77,
    slug: 'readyplay',
    name: 'Readyplay Gaming',
    image: 'readyplay.svg',
  },
  {
    id: 78,
    slug: 'reevo',
    name: 'Reevo',
    image: 'reevo.svg',
  },
  {
    id: 79,
    slug: 'relax',
    name: 'Relax Gaming',
    image: 'relax.svg',
  },
  {
    id: 80,
    slug: 'rubyplay',
    name: 'Ruby Play',
    image: 'rubyplay.svg',
  },
  {
    id: 81,
    slug: 'skywind',
    name: 'Skywind Group',
    image: 'skywind.svg',
  },
  {
    id: 82,
    slug: 'slotmill',
    name: 'Slotmill',
    image: 'slotmill.svg',
  },
  {
    id: 83,
    slug: 'smartsoft',
    name: 'Smartsoft Gaming',
    image: 'smartsoft.svg',
  },
  {
    id: 84,
    slug: 'spadegaming',
    name: 'Spade Gaming',
    image: 'spadegaming.svg',
  },
  {
    id: 85,
    slug: 'spinmatic',
    name: 'Spinmatic Entertainment',
    image: 'spinmatic.svg',
  },
  {
    id: 86,
    slug: 'spinomenal',
    name: 'Spinomenal',
    image: 'spinomenal.svg',
  },
  {
    id: 87,
    slug: 'spnmnl',
    name: 'Spinomenl',
    image: 'spnmnl.svg',
  },
  {
    id: 88,
    slug: 'spinoro',
    name: 'Spinoro',
    image: 'spinoro.svg',
  },
  {
    id: 89,
    slug: 'spinza',
    name: 'Spinza',
    image: 'spinza.svg',
  },
  {
    id: 90,
    slug: 'swintt',
    name: 'Swintt',
    image: 'swintt.svg',
  },
  {
    id: 91,
    slug: 'swntt',
    name: 'Swntt',
    image: 'swntt.svg',
  },
  {
    id: 92,
    slug: 'tadagaming',
    name: 'TaDa Gaming',
    image: 'tadagaming.svg',
  },
  {
    id: 93,
    slug: 'thunderkick',
    name: 'Thunderkick',
    image: 'thunderkick.svg',
  },
  {
    id: 94,
    slug: 'tomhornnative',
    name: 'Tom Horn Gaming',
    image: 'tomhornnative.svg',
  },
  {
    id: 95,
    slug: 'tornadogames',
    name: 'Tornado Games',
    image: 'tornadogames.svg',
  },
  {
    id: 96,
    slug: 'triplecherry',
    name: 'Triple Cherry',
    image: 'triplecherry.svg',
  },
  {
    id: 97,
    slug: 'truelab',
    name: 'True Lab',
    image: 'truelab.svg',
  },
  {
    id: 98,
    slug: 'turbogames',
    name: 'Turbo Games',
    image: 'turbogames.svg',
  },
  {
    id: 99,
    slug: 'vibragaming',
    name: 'Vibra Gaming',
    image: 'vibragaming.svg',
  },
  {
    id: 100,
    slug: 'wazdan',
    name: 'Wazdan',
    image: 'wazdan.svg',
  },
  {
    id: 101,
    slug: 'winfinity',
    name: 'Winfinity',
    image: 'winfinity.svg',
  },
  {
    id: 102,
    slug: 'wizard',
    name: 'Wizard Games',
    image: 'wizard.svg',
  },
  {
    id: 103,
    slug: 'yggdrasil',
    name: 'Yggdrasil',
    image: 'yggdrasil.svg',
  },
  {
    id: 104,
    slug: 'zillion',
    name: 'Zillion Games',
    image: 'zillion.svg',
  },
  {
    id: 105,
    slug: 'atmosphera',
    name: 'Atmosfera',
    image: 'atmosphera.svg',
  },
  {
    id: 106,
    slug: 'softswiss',
    name: 'Softswiss',
    image: 'softswiss.svg',
  },
  {
    id: 107,
    slug: 'groove',
    name: 'Groove',
    image: 'groove.svg',
  },
  {
    id: 108,
    slug: 'infin',
    name: 'Infin',
    image: 'infin.svg',
  },
  {
    id: 109,
    slug: 'redgenn',
    name: 'Redgenn',
    image: 'redgenn.svg',
  },
  {
    id: 110,
    slug: 'everymatrix',
    name: 'Every Matrix',
    image: 'everymatrix.svg',
  },
];

export const gameProvidersMap: Map<
  GameProviderId,
  Partial<GameProvider>
> = new Map(gameProviders.map(provider => [provider?.id, provider]));
